var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_c('Breadcrumb',{attrs:{"pgTitle":_vm.$t('nav.coupons'),"pgIcon":'bx bx-money',"refs":_vm.refs,"addNew":_vm.addNew}}),_c('DataTable',{attrs:{"refs":_vm.refs,"colspan":9,"hasSearchFilter":true,"hasDateFilter":true,"hasStatusFilter":true,"hasLecturerFilter":true,"hasHashId":true,"hasMultiSelect":false,"hasBulkAction":true,"HasExport":true,"hasShowEntries":true,"lblSearch":_vm.$t('app.search'),"fields":{
                'id': 'id',
                'title': 'title',
                'count': 'count',
                'value': 'value',
                'date': 'date',
            },"hasTitle":true,"title":_vm.$t('view.coupon_title'),"hasModule":true,"module":_vm.$t('app.module'),"hasCoupons":true,"coupons":_vm.$t('app.coupons'),"hasPercentage":true,"percentage":_vm.$t('app.percentage'),"hasDate":true,"hasStatus":true,"hasActions":true,"hasDeleteButton":true,"actionable":true,"sorDataTable":true},on:{"hasAddNewPermission":_vm.handlePermissoinChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }